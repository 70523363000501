import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo';
import Layout from '../components/layout';
import TestimonialBlock from '../components/testimonial-block/testimonial-block';
import PrimaryBanner from '../components/primary-banner/primary-banner';
// import SolutionsBlock from '../components/solutions-block-ecompare/solutions-block-ecompare';
import ItemsKeyFeatures from '../components/items-key-features/items-key-features';
import FaqBlock from '../components/faq-block/faq-block';
import OverlayBlock from '../components/overlay-block/overlay-block';

class ECapital extends React.Component {
    render() {
        const ecapitalPrimaryBanner = get(this, 'props.data.ecapitalPrimaryBanner');
        const ecapitalItemsKeyFeaturesHeading = get(this, 'props.data.ecapitalItemsKeyFeaturesHeading');
        const ecapitalItemsKeyFeatures = get(this, 'props.data.ecapitalItemsKeyFeatures');
        const ecapitalTestimonialHeading = get(this, 'props.data.ecapitalTestimonialHeading');
        const allContentfulTestimonialBlock = get(this, 'props.data.allContentfulTestimonialBlock');
        const ecapitalFaqBlockHeading = get(this, 'props.data.ecapitalFaqBlockHeading');
        const ecapitalFaqBlock = get(this, 'props.data.ecapitalFaqBlock');
        const ecapitalOverlayBlock = get(this, 'props.data.ecapitalOverlayBlock');

        return (
            <Layout location={this.props.location}>
                <Seo title="eCapital" />
                    <div className="container-fluid container-fluid--top">
                        <PrimaryBanner primaryBanner="ecapital-primary-banner"  headingText={ecapitalPrimaryBanner.title} subtitle={ecapitalPrimaryBanner.subtitle} image={ecapitalPrimaryBanner.backgroundImage.url} buttonPrimaryUrl={ecapitalPrimaryBanner.primaryButtonUrl} buttonPrimaryClass="button-primary-ecapital" buttonPrimaryText={ecapitalPrimaryBanner.primaryButtonText} buttonSecondaryUrl={ecapitalPrimaryBanner.secondaryButtonUrl} buttonSecondaryClass="button-secondary-ecapital" buttonSecondaryText={ecapitalPrimaryBanner.secondaryButtonText} />
                        {/* <SolutionsBlock solutions={ecompareSolutionsBlock} /> */}
                        <ItemsKeyFeatures itemsKeyFeaturesBlockClass="ecapital-items-key-features" itemsKeyFeaturesHeading={ecapitalItemsKeyFeaturesHeading} ItemsKeyFeatures={ecapitalItemsKeyFeatures.nodes} />
                        <TestimonialBlock ecompareTestimonialBlockClass="ecapital-testimonial-block" testimonialHeading={ecapitalTestimonialHeading} testimonials={allContentfulTestimonialBlock.nodes} />
                        <FaqBlock faqBlockHeading={ecapitalFaqBlockHeading} faqBlock={ecapitalFaqBlock.nodes} />
                        <OverlayBlock ecaapitalOverlayBlockClass="ecapital-overlay-block" headingText={ecapitalOverlayBlock.title} buttonUrl={ecapitalOverlayBlock.buttonUrl} buttonText={ecapitalOverlayBlock.buttonText} image={ecapitalOverlayBlock.backgroundImage.url} />
                    </div>
            </Layout>
        )
    }
}

export default ECapital;

export const query = graphql`
query ecapitalQuery {
    ecapitalPrimaryBanner: contentfulPrimaryBanner(primaryBannerName: {eq: "ecapitalPrimaryBanner"}) {
        logo {
            url
        }
        title
        subtitle
        primaryBannerName
        primaryButtonUrl
        primaryButtonText
        secondaryButtonUrl
        secondaryButtonText
        backgroundImage {
            url
        }
    }
    ecapitalItemsKeyFeaturesHeading: contentfulMulticolumnHeading (multicolumnHeadingName: {eq: "ecapitalItemsKeyFeaturesHeading"}) {
        titleText
        descriptionText
    }
    ecapitalItemsKeyFeatures: allContentfulItemsKeyFeatures (sort: { fields: orderId, order: ASC } filter: {website: {eq: "eCapital"}}) {
        nodes {
          orderId
          headingText
          descriptionText {
            raw
          }
          image {
            url
          }
        }
    }
    ecapitalTestimonialHeading: contentfulTestimonialHeading (testimonialHeadingName: {eq: "ecapitalTestimonialHeading"}) {
        quoteTitle
      }
    allContentfulTestimonialBlock(filter: {website: {eq: "eCompare"}}) {
        nodes {
            quote
            clientName
            positionAndCompany
        }
    }
    ecapitalFaqBlockHeading: contentfulMulticolumnHeading (multicolumnHeadingName: {eq: "ecapitalFaqBlockHeading"}) {
        titleText
        descriptionText
    }
    ecapitalFaqBlock: allContentfulFaqBlock (sort: { fields: orderId, order: ASC } filter: {website: {eq: "eCapital"}}) {
        nodes {
          orderId
          questionText
          answerText {
            raw
          }
        }
    }
    ecapitalOverlayBlock: contentfulOverlayBlock(overlayBlockName: {eq: "ecapitalOverlayBlock"}) {
        title
        buttonUrl
        buttonText
        backgroundImage {
                url
        }
    }
}
`